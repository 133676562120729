import { useEffect, useState } from "react"
import * as React from "react"
import { Col, Row } from "react-bootstrap"
import { Link, useRouteMatch, useHistory } from "react-router-dom"
import styled from "styled-components"
import { UserOrganizationVisitSource } from "../backendServices/TrackingServices"
import { Category, Contact, Exhibitor, Person, Stand } from "../backendServices/Types"
import {
    BasisPremiumConfig,
    BasisPremiumType,
    getBasisPremiumConfig,
    getDefaultBasisPremiumConfig,
    TileSize
} from "../branding/BasisPremiumBranding"
import branding from "../branding/branding"
import { DetailNavLink, DetailNavLinkType } from "../contentArea/detailPages/DetailNavLink"
import ActionsWrapper from "../contentArea/entitiesActions/ActionsWrapper"
import { useLanguageState } from "../globalStates/LanguageState"
import { exhibitorStartupPageRoute, mediaPartnerPageRoute, sponsorsPageRoute } from "../navigationArea/RoutePaths"
import MeetingDetails from "../ui/MeetingDetails"
import { sectionOrder, Sections, SectionType } from "../utils/searchUtils"
import CrsPersons from "./CrsPersons"
import { IconLinkRightArrow } from "./Icons"
import BadgeArea from "./BadgeArea"
import { buildHallplanLink } from "../contentArea/hallplan/HallplanNavLink"
import _ from "lodash"

//used for custom Column element (CompanyCol)
const maxColumnWidth = 24

/* #region  Global styled components */
const CompanyGrid = styled.div`
    width: 100%;
    display: flex;
    font-family: ${branding.font1};
    margin: 0;
    /* padding: 0 20px; */
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 55px;
`
const CompanyRow = styled(Row)`
    width: 100%;
    margin: 0;
    padding: 0;
`
interface CompanyColProps {
    imageurl: string
    height: string
    basisPremiumConfig: BasisPremiumConfig
    isfiller?: boolean
    isNoneClickable?: boolean
    xs: number
}

const CompanyCol = styled.div<CompanyColProps>`
    width: ${(props) => (props.xs / maxColumnWidth) * 100 + "%"};
    border: ${(props) =>
        props.basisPremiumConfig.tileBackgroundImageVisible ? "none" : "0.2px solid " + branding.mainInfoColor};
    height: ${(props) => props.height};
    background: ${(props) => (props.imageurl !== "" ? "url(" + props.imageurl + ")" : "white")};
    background-size: ${(props) => (props.basisPremiumConfig.tileSize === TileSize.SMALL ? "40%" : "cover")};
    background-position: ${(props) =>
        props.basisPremiumConfig.tileSize === TileSize.SMALL ? "center" : branding.exhibitorsPageContent.largeTileBgPosition};
    background-repeat: no-repeat;
    padding: 0;
    cursor: ${(props) =>
        !props.isfiller && props.basisPremiumConfig.tileSize !== TileSize.SMALL
            ? "pointer"
            : !props.isfiller && props.basisPremiumConfig.tileSize === TileSize.SMALL && props.isNoneClickable
            ? "pointer"
            : "default"};
    float: left;
    position: relative;
    border: 5px solid ${branding.organizationDetailPageContent.companiesTilesBorderColor ?? "#fff"};
    border-radius: 10px;

    @media (max-width: 1400px) {
        height: ${(props) => (props.basisPremiumConfig.tileSize === TileSize.SMALL ? "150px" : "300px")};
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${branding.exhibitorsPageContent.companiesTilesLayoutOverlay ?? "rgba(0,0,0, .5)"};
        box-shadow: inset 0px 0px 0px ${branding.exhibitorsPageContent.exhibitorTilesBorderWidth || 0}
            ${branding.exhibitorsPageContent.exhibitorTilesBorderColor || ""};
        border-radius: 5px;
    }

    & .detailNavLink {
        height: 100%;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 2;
    }
`

const CompanyColFiller = styled.div<CompanyColProps>`
    width: ${(props) => (props.xs / maxColumnWidth) * 100 + "%"};
    border: ${(props) =>
        props.basisPremiumConfig.tileBackgroundImageVisible ? "none" : "0.2px solid " + branding.mainInfoColor};
    height: ${(props) => props.height};
    background: ${(props) => (props.imageurl !== "" ? "url(" + props.imageurl + ")" : "white")};
    background-size: ${(props) => (props.basisPremiumConfig.tileSize === TileSize.SMALL ? "40%" : "cover")};
    background-position: ${(props) =>
        props.basisPremiumConfig.tileSize === TileSize.SMALL ? "center" : branding.exhibitorsPageContent.largeTileBgPosition};
    background-repeat: no-repeat;
    padding: 0;
    cursor: ${(props) =>
        !props.isfiller && props.basisPremiumConfig.tileSize !== TileSize.SMALL
            ? "pointer"
            : !props.isfiller && props.basisPremiumConfig.tileSize === TileSize.SMALL && props.isNoneClickable
            ? "pointer"
            : "default"};
    float: left;
    position: relative;
    border: 5px solid #fff;
    border-radius: 10px;

    @media (max-width: 1400px) {
        height: ${(props) => (props.basisPremiumConfig.tileSize === TileSize.SMALL ? "150px" : "300px")};
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        box-shadow: inset 0px 0px 0px #fff;
        border-radius: 15px;
    }

    & .detailNavLink {
        height: 100%;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 2;
    }
`

const CompanyColSmall = styled(CompanyCol)`
    &::after {
        border-radius: 5px;
    }

    & > :first-child {
        display: none;
    }

    &:hover > :first-child {
        display: flex;
    }
    &:hover > :nth-child(2) {
        display: none;
    }
`

const CompanyColNone = styled.div<{ xs: number }>`
    width: ${(props) => (props.xs / maxColumnWidth) * 100 + "%"};
    float: left;
    position: relative;
    padding: 0;
    cursor: default;
`

const CompanyLogoDiv = styled.div`
    width: 100px;
    height: 100px;
    background: white;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    @media (max-width: 1500px) {
        width: 60px;
        height: 60px;
        padding: 5px;
    }

    & p {
        font-family: ${branding.font1};
        max-width: 40px;
        font-size: 14px;
        line-height: 0.6rem;
        color: rgb(0, 0, 0);
        text-align: center;
        display: inline-table;
        margin-bottom: 0;
        white-space: normal;
        word-break: break-word;
    }
`

const CompanyLogo = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`
const CompanyDescriptionRoot = styled(Row)`
    padding: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    z-index: 2;
    padding-top: 0px;

    @media (max-width: 1400px) {
        padding: 15px;
    }
`
interface CompanyDescriptionProps {
    color: string
}

const CompanyDescription = styled.div<CompanyDescriptionProps>`
    max-width: 350px;
    color: ${(props) => props.color};
    margin-right: 40px;
    display: inline-block;
    max-height: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    font-size: 12px;
    font-weight: 400;

    @media (max-width: 1600px) {
        font-size: 12px;
    }

    @media (max-width: 1400px) {
        font-size: 12px;
        -webkit-line-clamp: 3;
    }
`

interface CompanyNameProps {
    color: string
}

const CompanyName = styled.h5<CompanyNameProps>`
    color: ${(props) => props.color};

    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 18px;
    font-weight: 700;
    margin-top: 0.5rem;

    @media (max-width: 1400px) {
        font-size: 16px;
    }
`

const CompanyStands = styled.div<{ view: "list" | "tile" }>`
    color: ${(props) => (props.view === "list" ? branding.primaryColor : "#fff")};
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 12px;
    margin-bottom: ${(props) => (props.view === "list" ? "10px" : "")};
`
const CompanyStandsHallplan = styled.div`
    margin-top: 5px;
`

const CompanyStandsHallName = styled.div`
    display: inline-block;
    white-space: pre-line;
`
const CompanyStandsStandName = styled.div`
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
`
/* #endregion */

/* #region  Tile header */
const TileHeaderRow = styled(Row)`
    margin-bottom: 3rem;
    padding: 25px;
    //padding: 30px;
    position: relative;
    z-index: 2;

    @media (max-width: 1400px) {
        padding: 15px;
        margin-bottom: 0;
    }
`
interface CompanyLogoColProps {
    type: BasisPremiumType
}
const CompanyLogoCol = styled(Col)<CompanyLogoColProps>``
interface TileButtonsColProps {
    type: BasisPremiumType
}
const TileButtonsCol = styled(Col)<TileButtonsColProps>`
    position: relative;
`

const TileButtonsRow = styled(Row)`
    position: absolute;
    display: block;
    margin: 0px;
    justify-content: flex-end;
    right: 10px;
`

interface TileHeaderProps {
    tileData: TileData
    customBookmarkFunction?: (id: string) => void
}

const TileHeader: React.FC<TileHeaderProps> = (props) => {
    const hiddenDetails =
        props.tileData.basisPremiumConfig.tileSize === TileSize.QUARTER ||
        props.tileData.basisPremiumConfig.tileSize === TileSize.SMALL
    return (
        <TileHeaderRow>
            <CompanyLogoCol xs={6}>
                <CompanyLogoDiv>
                    {props.tileData.pictureURL ? (
                        <CompanyLogo src={props.tileData.pictureURL} />
                    ) : branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles ? (
                        <CompanyLogo src={"/branding/rsz_defaultgrey.png"} />
                    ) : (
                        <p style={{ fontFamily: branding.font1, fontSize: "16px" }}>{props.tileData.initials || ""}</p>
                    )}
                </CompanyLogoDiv>
            </CompanyLogoCol>
            <TileButtonsCol xs={6}>
                <TileButtonsRow>
                    <ActionsWrapper
                        layout="tile-layout"
                        organization={props.tileData}
                        basisPremiumConfig={props.tileData.basisPremiumConfig}
                        basisPremiumType={props.tileData.basisPremiumType}
                        entity={{ id: props.tileData.id, entityType: "organization" }}
                        exhibitorHasContacts={props.tileData && props.tileData.contacts && props.tileData.contacts.length > 0}
                    />
                    <div hidden={hiddenDetails}>
                        <MeetingDetails content={props.tileData} lobby={false} />
                    </div>
                </TileButtonsRow>
            </TileButtonsCol>
        </TileHeaderRow>
    )
}
// const MeetingDescriptionRoot = styled(Row)`
//     margin-top: 35px;
//     display: flex;
//     justify-content: flex-end;
//     position: absolute;
//     height: 50px;
//     width: 160px;
//     float: right;
//     right: 0;

//     @media (max-width: 1400px) {
//         padding: 15px;
//         margin-bottom: 0;
//     }
// `
/* #endregion */

/* #region  Tile body */
interface HalleStandComponentProps {
    stands: Stand[] | null
    hall?: string
    view: "list" | "tile"
    setIsStandClicked?: (value: boolean) => void | undefined
    setSelectedStand?: (value: string | undefined) => void | undefined
    setStandName?: (value: string | undefined) => void | undefined
    isStandSelected?: boolean

    /**
     * Defines if a component is shown inside an exhibitors/products/eventdates list in the hallplan page
     */
    hallplan?: boolean

    /**
     * Defines the value of the toggle for visibility of all halls with stands instead of the limited number shown by default
     * (implemented in hallplan page)
     */
    showAllHalls?: boolean

    /**
     * Toggles the visibility of the toggle for visibility of all halls with stands instead of the limited number shown by default
     * (implemented in hallplan page)
     */
    setShowAllHallsButtonVisible?: (value: boolean) => void
}
export const HalleStandComponent = (props: HalleStandComponentProps) => {
    const [halleStandText, setHalleStandText] = useState("")
    const history = useHistory()
    const options: { value: string; label: string }[] = []
    const stands: Stand[] = _.sortBy(props.stands || [], "hallNr")

    const [hallsWithStands, setHallsWithStands] = useState<{ hall: string; stands: Stand[] }[]>([])

    useEffect(() => {
        if (props.stands && stands.length > 0) {
            let halleStandText = ""
            let halleStandsLength = stands.length

            if (stands && halleStandsLength) {
                halleStandText = (stands[0].standName || stands[0].displayName).trimStart()

                if (halleStandsLength > 1 && branding.exhibitorsPageContent.showAllHalleStandInfo) {
                    for (let index = 1; index < halleStandsLength; index++) {
                        const additionalHalleStandText = `${", " + (stands[index].standName || stands[index].displayName)}`
                        halleStandText += additionalHalleStandText
                    }
                }

                setHalleStandText(halleStandText)
            }

            let halls: string[] = []
            let hallsWithStandsTemp: { hall: string; stands: Stand[] }[] = []

            stands?.forEach((stand) => {
                options.push({ label: stand.standNameShort, value: stand.standNr })
                halls.push(stand.hallName)
                halls = _.uniqBy(halls, function (hall) {
                    return hall
                })
            })
            halls.forEach((hall: string) => {
                let standsTemp: Stand[] = stands.filter((stand: Stand) => stand.hallName === hall)
                hallsWithStandsTemp.push({ hall: hall, stands: standsTemp })
            })

            setHallsWithStands(hallsWithStandsTemp)

            if (props.setShowAllHallsButtonVisible) {
                props.setShowAllHallsButtonVisible(hallsWithStandsTemp.length > branding.hallPlanPageBranding.defaultHallsNumber)
            }
        }
        // eslint-disable-next-line
    }, [props.stands])

    if (halleStandText)
        return (
            <>
                {!props.hallplan && <CompanyStands view={props.view}>{halleStandText}</CompanyStands>}
                {props.hallplan && (
                    <>
                        {hallsWithStands
                            .slice(
                                0,
                                props.showAllHalls ? hallsWithStands.length : branding.hallPlanPageBranding.defaultHallsNumber
                            )
                            .map((item: { hall: string; stands: Stand[] }, index: number) => {
                                return (
                                    <CompanyStandsHallplan>
                                        <CompanyStandsHallName>{item.hall}</CompanyStandsHallName>
                                        {item.stands?.map((stand: Stand, i: number) => (
                                            <CompanyStandsStandName
                                                key={i}
                                                onClick={() => {
                                                    if (!props.isStandSelected) {
                                                        props.setIsStandClicked!(true)
                                                        props.setSelectedStand!(stand.standNr)
                                                        props.setStandName!(stand.standNameShort)
                                                    }
                                                    history.push(buildHallplanLink(stand.hallNr, stand.standNr))
                                                }}
                                            >
                                                <div style={{ display: "inline-block", marginRight: "5px" }}>{" | "}</div>
                                                <div style={{ display: "inline-block", textDecoration: "underline" }}>
                                                    {stand.standNameShort}
                                                </div>
                                            </CompanyStandsStandName>
                                        ))}
                                        {!props.showAllHalls &&
                                            hallsWithStands.length > branding.hallPlanPageBranding.defaultHallsNumber &&
                                            index === branding.hallPlanPageBranding.defaultHallsNumber - 1 && (
                                                <CompanyStandsHallName style={{ marginLeft: "5px" }}>...</CompanyStandsHallName>
                                            )}
                                    </CompanyStandsHallplan>
                                )
                            })}
                    </>
                )}
            </>
        )

    return null
}

const ConnectWithUsRow = styled(Row)`
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
`

interface TileBodyProps {
    content: TileData
    strings: any
}

const TileBody: React.FC<TileBodyProps> = (props) => {
    const color = props.content.basisPremiumConfig.tileBackgroundImageVisible
        ? "white"
        : branding.exhibitorsPageContent.companyTileBasicTextColor
    const hiddenContacts = props.content.basisPremiumConfig.tileSize !== TileSize.FULL
    const size =
        props.content.basisPremiumConfig.tileSize === TileSize.FULL
            ? 4
            : props.content.basisPremiumConfig.tileSize === TileSize.THREE_QUARTERS ||
              props.content.basisPremiumConfig.tileSize === TileSize.HALF
            ? 6
            : 12
    const persons = (props.content.contacts as any as Person[])?.filter((person) => person.visible)

    return (
        <CompanyDescriptionRoot>
            <Col xs={size}>
                {branding.exhibitorsPageContent.showHalleStandInfo && (
                    <HalleStandComponent stands={props.content.stands} view="tile" />
                )}
                <CompanyName color={color}>{props.content.name}</CompanyName>
                {props.content.teaser && (
                    <CompanyDescription color={color}>
                        {props.content.teaser.split("\\n").map((item, i) => (
                            <span key={i}>
                                {item}
                                <br />
                            </span>
                        ))}
                    </CompanyDescription>
                )}
                {props.content.categories && props.content.categories?.length > 0 && (
                    <BadgeArea
                        maxBadgeCount={branding.exhibitorsPageContent.exhibitorTilesMaxBadgeCount}
                        categories={props.content.categories}
                        marginTop="3px"
                        marginBottom="0"
                        fontSize={branding.categoryBadgesShowfloorTextSize}
                    />
                )}
            </Col>
            <Col xs={size} hidden={hiddenContacts}>
                {persons && persons.length > 0 && (
                    <>
                        <ConnectWithUsRow>{props.strings.organizationDetailPageContent.connectWithUs}</ConnectWithUsRow>
                        {/* TODO remove cast. Therefore Contact and Person Type should be merged */}
                        <CrsPersons
                            key={props.content.id}
                            layoutType={1}
                            showModalPopup={false}
                            persons={persons}
                            personsToShow={5}
                        />
                    </>
                )}
            </Col>
        </CompanyDescriptionRoot>
    )
}
/* #endregion */

/* #region  Organization tile main content */
const HoverDiv = styled.div`
    background-color: ${branding.exhibitorsPageContent.companiesTilesLayoutHoverOverlay ?? "rgba(0, 0, 0, 0.2)"};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
`

const TileButtonsNoneDiv = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 15px;
`
const TileButtonsNoneDivArea = styled.div`
    @media (max-width: 2000px) {
        display: contents;
    }
`

const CompanyNameDiv = styled.div`
    font-size: 16px;
    color: ${branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000"};

    p {
        max-width: 100%;
        color: ${(props) => props.color};
        display: inline-block;
        max-height: 200px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        word-break: break-word;
        margin-top: 0;
        margin-bottom: 0;

        @media (max-width: 1600px) {
            max-width: 70%;
        }
    }
`

const CompanyNameDivAlt = styled(CompanyNameDiv)`
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & p {
        word-break: inherit;
        text-align: center;
    }
`

const SectionHeaderTitle = styled.div`
    width: 100%;
    margin: 48px 0 16px 25px;
    color: ${branding.mainInfoColor ?? "#000"};

    font-size: ${branding.exhibitorsPageContent.sectionTitleFontSize};
    font-family: ${branding.exhibitorsPageContent.sectionTitleFontFamily};
    text-transform: ${branding.exhibitorsPageContent.sectionTitleTextTransform};
    font-weight: ${branding.exhibitorsPageContent.sectionTitleFontWeight};
    letter-spacing: ${branding.exhibitorsPageContent.sectionTitleLetterSpacing};
`

interface FillerTemplateNameDivProps {
    display: string
    color: string
    height: string
}

const FillerTemplateNameDiv = styled.div<FillerTemplateNameDivProps>`
    display: ${(props) => props.display};
    color: ${(props) => props.color};
    height: 50%;
    /* width: 100%; */
    position: relative;
    z-index: ${(props) => (props.color === "#FFF" ? 2 : 0)};

    h4 {
        font-size: 18px;
        font-weight: 700;
    }

    h6 {
        font-size: 16px;
        font-weight: 500;
    }

    p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        width: 100%;
    }

    @media (max-width: 1400px) {
        font-size: 12px;
    }
`

interface FillerTemplateLinkDivProps {
    display: string
    color?: string
}

const FillerTemplateLinkDiv = styled.div<FillerTemplateLinkDivProps>`
    display: ${(props) => props.display};
    font-size: 18px;
    color: ${(props) => (props.color ? props.color : "#FFF")};
    position: relative;
    z-index: ${(props) => (props.color === "#FFF" ? 2 : 100)};
    height: 50%;
    align-items: flex-end;
    & a {
        color: ${(props) => (props.color ? props.color : "#FFF")};
        text-decoration: none;
    }

    @media (max-width: 1600px) {
        font-size: 15px;
    }
`

interface FillerTemplateLinkParagraphProps {
    borderBottom: string
}

const FillerTemplateLinkParagraph = styled.p<FillerTemplateLinkParagraphProps>`
    border-bottom: ${(props) => props.borderBottom};
    font-size: 18px;
    word-break: break-word;
    white-space: pre-wrap;
    margin-bottom: 0px;

    @media (max-width: 2100px) {
        font-size: 16px;
    }

    @media (max-width: 1800px) {
        font-size: 15px;
        margin-top: 20px;
    }

    @media (max-width: 1600px) {
        font-size: 15px;
    }

    @media (max-width: 1600px) {
        font-size: 12px;
    }

    @media (max-width: 1400px) {
        font-size: 10px;
    }
`

interface FillerTileTemplateProps {
    content: TileData
    display: string
}

const FillerTileTemplate: React.FC<FillerTileTemplateProps> = (props) => {
    const color =
        props.content.basisPremiumConfig.tileSize === TileSize.QUARTER
            ? branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000"
            : "#FFF"
    const height = props.content.basisPremiumConfig.tileSize === TileSize.QUARTER ? "80%" : "85%"
    // const borderBottom = props.content.basisPremiumConfig.tileSize === TileSize.QUARTER ? "1px solid " + branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000" : "1px solid white";

    return (
        <>
            <FillerTemplateNameDiv className="row p-4" display={props.display} color={color} height={height}>
                <div>
                    <h4>{props.content.name}</h4>
                    {props.content.teaser && <h6>{props.content.teaser}</h6>}
                </div>
            </FillerTemplateNameDiv>
            <FillerTemplateLinkDiv
                className="row p-4"
                display={props.content.basisPremiumConfig.tileSize === TileSize.SMALL ? "flex" : props.display}
                color={color}
            >
                {props.content.fillerLink && !props.content.fillerLink?.startsWith("/") && (
                    <a href={props.content.fillerLink} target="_blank" rel="noopener noreferrer">
                        <FillerTemplateLinkParagraph borderBottom={"0px solid " + color}>
                            {props.content.fillerLinkText}{" "}
                            <span style={{ marginLeft: "10px" }}>{IconLinkRightArrow({ fill: color })}</span>
                        </FillerTemplateLinkParagraph>
                    </a>
                )}
                {props.content.fillerLink?.startsWith("/") && (
                    <Link to={props.content.fillerLink}>
                        <FillerTemplateLinkParagraph borderBottom={"0px solid"}>
                            {props.content.fillerLinkText}
                            <span style={{ marginLeft: "10px" }}>{IconLinkRightArrow({ fill: color })}</span>
                        </FillerTemplateLinkParagraph>
                    </Link>
                )}
            </FillerTemplateLinkDiv>
        </>
    )
}

interface FillerNoneDivProps {
    background: string
}

const FillerNoneDiv = styled.div<FillerNoneDivProps>`
    height: 200px;
    width: 100%;
    padding: 1rem 2rem;
    background: ${(props) => props.background};
    border: 1px solid ${branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000"};
    position: relative;
    z-index: 2;
    border: 5px solid #fff;
    box-shadow: inset 0px 0px 0px ${branding.exhibitorsPageContent.exhibitorTilesBorderWidth || 0}
        ${branding.exhibitorsPageContent.exhibitorTilesBorderColor || ""};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;

    @media (max-width: 1400px) {
        height: 150px;
    }
`

interface FillerNoneProps {
    tileData: TileData
}

const FillerNone: React.FC<FillerNoneProps> = (props) => {
    const color =
        props.tileData.fillerColor === "#FFF" ? branding.exhibitorsPageContent.companyTileBasicTextColor ?? "#000" : "#FFF"
    return (
        <FillerNoneDiv background={props.tileData.fillerColor!}>
            <FillerTemplateNameDiv className="row" height={"70%"} color={color} display={"flex"}>
                <p>{props.tileData.name}</p>
            </FillerTemplateNameDiv>
            <FillerTemplateLinkDiv className="row" display={"flex"} color={color}>
                {!props.tileData.fillerLink?.startsWith("/") && (
                    <a href={props.tileData.fillerLink} target="_blank" rel="noopener noreferrer">
                        <FillerTemplateLinkParagraph borderBottom={"0px solid " + color}>
                            {props.tileData.fillerLinkText}
                            <span style={{ marginLeft: "10px" }}>{IconLinkRightArrow({ fill: color })}</span>
                        </FillerTemplateLinkParagraph>
                    </a>
                )}
                {props.tileData.fillerLink?.startsWith("/") && (
                    <Link to={props.tileData.fillerLink}>
                        <FillerTemplateLinkParagraph borderBottom={"0px solid " + color}>
                            {props.tileData.fillerLinkText}
                            <span style={{ marginLeft: "10px" }}>{IconLinkRightArrow({ fill: color })}</span>
                        </FillerTemplateLinkParagraph>
                    </Link>
                )}
            </FillerTemplateLinkDiv>
        </FillerNoneDiv>
    )
}

interface TileData {
    id: string
    name: string
    teaser?: string
    pictureURL?: string
    backgroundImageURL?: string
    basisPremiumType?: BasisPremiumType
    basisPremiumConfig: BasisPremiumConfig
    contacts: Contact[]
    isFiller?: boolean
    fillerColor?: string
    fillerLinkText?: string
    fillerLink?: string
    initials?: string
    filler: TileData | null
    stands: Stand[] | null
    categories: Category[]
}

interface OrganizationTileProps {
    tileData: TileData
    src: UserOrganizationVisitSource
    searchKrit?: string
    strings: any
}

const OrganizationTile: React.FC<OrganizationTileProps> = (props) => {
    const isNoneClickable = branding.exhibitorsPageContent.companyTileNoneClickable
    const tileSize = props.tileData.basisPremiumConfig.tileSize

    let imageUrl = props.tileData.backgroundImageURL ? props.tileData.backgroundImageURL : "/branding/default_brand_card.jpg"
    if (!props.tileData.basisPremiumConfig.tileBackgroundImageVisible) imageUrl = ""

    if (tileSize === TileSize.SMALL) {
        if (props.tileData.isFiller) {
            return (
                <CompanyColNone xs={3}>
                    <FillerNone tileData={props.tileData} />
                    <FillerNone tileData={props.tileData.filler!} />
                </CompanyColNone>
            )
        } else {
            return (
                <CompanyColNone xs={3}>
                    <DetailNavLink
                        id={props.tileData.id}
                        type="organization"
                        name={props.tileData.name}
                        source={props.src}
                        searchKrit={props.searchKrit}
                        onClick={(e) => {
                            if (!isNoneClickable) {
                                e.preventDefault()
                                e.stopPropagation()
                            }
                        }}
                    >
                        <CompanyColSmall
                            xs={24}
                            height="200px"
                            imageurl={props.tileData.pictureURL!}
                            basisPremiumConfig={props.tileData.basisPremiumConfig}
                            isfiller={false}
                            isNoneClickable={isNoneClickable}
                        >
                            <HoverDiv>
                                <TileButtonsNoneDiv className="row">
                                    <TileButtonsNoneDivArea className="col-6 p-0">
                                        <ActionsWrapper
                                            layout="tile-layout"
                                            organization={props.tileData}
                                            basisPremiumConfig={props.tileData.basisPremiumConfig}
                                            basisPremiumType={props.tileData.basisPremiumType}
                                            entity={{ id: props.tileData.id, entityType: "organization" }}
                                            exhibitorHasContacts={
                                                props.tileData && props.tileData.contacts && props.tileData.contacts.length > 0
                                            }
                                        />
                                    </TileButtonsNoneDivArea>
                                    <div style={{ width: "100%" }}>
                                        <CompanyNameDiv className="col-12 p-0">
                                            <p>{props.tileData.name}</p>
                                        </CompanyNameDiv>
                                    </div>
                                </TileButtonsNoneDiv>
                            </HoverDiv>
                            {!props.tileData.pictureURL && (
                                <CompanyNameDivAlt>
                                    <p>{props.tileData.name}</p>
                                </CompanyNameDivAlt>
                            )}
                        </CompanyColSmall>
                    </DetailNavLink>
                    {props.tileData.filler && !props.tileData.filler.isFiller && (
                        <DetailNavLink
                            id={props.tileData.filler.id}
                            type="organization"
                            name={props.tileData.filler.name}
                            source={props.src}
                            searchKrit={props.searchKrit}
                            onClick={(e) => {
                                if (!isNoneClickable) {
                                    e.preventDefault()
                                    e.stopPropagation()
                                }
                            }}
                        >
                            <CompanyColSmall
                                xs={24}
                                height="200px"
                                imageurl={props.tileData.filler.pictureURL!}
                                basisPremiumConfig={props.tileData.basisPremiumConfig}
                                isfiller={false}
                                isNoneClickable={isNoneClickable}
                            >
                                <HoverDiv>
                                    <TileButtonsNoneDiv className="row">
                                        <TileButtonsNoneDivArea className="col-6 p-0">
                                            <ActionsWrapper
                                                layout="tile-layout"
                                                organization={props.tileData.filler}
                                                basisPremiumConfig={props.tileData.filler.basisPremiumConfig}
                                                basisPremiumType={props.tileData.filler.basisPremiumType}
                                                entity={{ id: props.tileData.filler.id, entityType: "organization" }}
                                                exhibitorHasContacts={
                                                    props.tileData.filler &&
                                                    props.tileData.filler.contacts &&
                                                    props.tileData.filler.contacts.length > 0
                                                }
                                            />
                                        </TileButtonsNoneDivArea>
                                        <div style={{ width: "100%" }}>
                                            <CompanyNameDiv className="col-12 p-0">
                                                <p>{props.tileData.filler.name}</p>
                                            </CompanyNameDiv>
                                        </div>
                                    </TileButtonsNoneDiv>
                                </HoverDiv>
                                {!props.tileData.filler.pictureURL && (
                                    <CompanyNameDivAlt>
                                        <p>{props.tileData.filler.name}</p>
                                    </CompanyNameDivAlt>
                                )}
                            </CompanyColSmall>
                        </DetailNavLink>
                    )}
                    {props.tileData.filler && props.tileData.filler.isFiller && (
                        <CompanyColFiller
                            xs={24}
                            style={{ border: 0 }}
                            height="200px"
                            basisPremiumConfig={props.tileData.basisPremiumConfig}
                            isfiller={true}
                            imageurl=""
                        >
                            <FillerNone tileData={props.tileData.filler} />
                        </CompanyColFiller>
                    )}
                </CompanyColNone>
            )
        }
    } else if (tileSize === TileSize.QUARTER) {
        const display = props.tileData.backgroundImageURL ? "none" : "flex"

        if (props.tileData.isFiller) {
            return (
                <CompanyCol
                    xs={6}
                    className="pt-2 pb-2 pl-4 pr-4"
                    imageurl={props.tileData.backgroundImageURL!}
                    height="400px"
                    basisPremiumConfig={props.tileData.basisPremiumConfig}
                    isfiller={true}
                >
                    <FillerTileTemplate content={props.tileData} display={display} />
                </CompanyCol>
            )
        } else {
            return (
                <CompanyCol
                    xs={6}
                    imageurl={imageUrl}
                    height="400px"
                    basisPremiumConfig={props.tileData.basisPremiumConfig}
                    isfiller={false}
                >
                    <DetailNavLink
                        id={props.tileData.id}
                        type="organization"
                        name={props.tileData.name}
                        source={props.src}
                        searchKrit={props.searchKrit}
                    >
                        <TileHeader tileData={props.tileData} />
                        <TileBody content={props.tileData} strings={props.strings} />
                    </DetailNavLink>
                </CompanyCol>
            )
        }
    } else if (tileSize === TileSize.HALF) {
        if (props.tileData.isFiller) {
            return (
                <CompanyCol
                    xs={12}
                    className="pt-2 pb-2 pl-4 pr-4"
                    imageurl={"/branding/custom-filler-image.png"}
                    height={"400px"}
                    basisPremiumConfig={props.tileData.basisPremiumConfig}
                    isfiller={true}
                >
                    <FillerTileTemplate content={props.tileData} display={"flex"} />
                </CompanyCol>
            )
        } else {
            return (
                <CompanyCol
                    xs={12}
                    imageurl={imageUrl}
                    height={"400px"}
                    basisPremiumConfig={props.tileData.basisPremiumConfig}
                    isfiller={false}
                >
                    <DetailNavLink
                        id={props.tileData.id}
                        type="organization"
                        name={props.tileData.name}
                        source={props.src}
                        searchKrit={props.searchKrit}
                    >
                        <TileHeader tileData={props.tileData} />
                        <TileBody content={props.tileData} strings={props.strings} />
                    </DetailNavLink>
                </CompanyCol>
            )
        }
    } else if (tileSize === TileSize.THREE_QUARTERS) {
        return (
            <CompanyCol
                xs={18}
                imageurl={imageUrl}
                height={"400px"}
                basisPremiumConfig={props.tileData.basisPremiumConfig}
                isfiller={false}
            >
                <DetailNavLink
                    id={props.tileData.id}
                    type="organization"
                    name={props.tileData.name}
                    source={props.src}
                    searchKrit={props.searchKrit}
                >
                    <TileHeader tileData={props.tileData} />
                    <TileBody content={props.tileData} strings={props.strings} />
                </DetailNavLink>
            </CompanyCol>
        )
    } else if (tileSize === TileSize.FULL) {
        return (
            <CompanyCol
                xs={24}
                imageurl={imageUrl}
                height={"400px"}
                basisPremiumConfig={props.tileData.basisPremiumConfig}
                isfiller={false}
            >
                <DetailNavLink
                    id={props.tileData.id}
                    type="organization"
                    name={props.tileData.name}
                    source={props.src}
                    searchKrit={props.searchKrit}
                >
                    <TileHeader tileData={props.tileData} />
                    <TileBody content={props.tileData} strings={props.strings} />
                </DetailNavLink>
            </CompanyCol>
        )
    } else {
        return null
    }
}
/* #endregion */

function getNumColumnsForTileSize(tileSize: TileSize) {
    if (tileSize === TileSize.FULL) return 24
    if (tileSize === TileSize.THREE_QUARTERS) return 18
    if (tileSize === TileSize.HALF) return 12
    if (tileSize === TileSize.QUARTER) return 6
    return 3
}

function getTileSizeForNumColumns(size: number) {
    if (size === 24) return TileSize.FULL
    if (size === 18) return TileSize.THREE_QUARTERS
    if (size === 12) return TileSize.HALF
    if (size === 6) return TileSize.QUARTER
    return TileSize.SMALL
}

function getNeededFillerSizes(size: number): Array<number> {
    if (size === 21) return [12, 6, 3]
    if (size === 18) return [12, 6]
    if (size === 15) return [12, 3]
    if (size === 12) return [12]
    if (size === 9) return [6, 3]
    if (size === 6) return [6]
    return [3]
}

function getFillerData(tileSize: TileSize, color: string, fillersData: any): TileData | null {
    let obj
    let randTile
    if (tileSize === TileSize.HALF) {
        randTile = Math.floor(Math.random() * fillersData.standard.length + 0)
        obj = fillersData.standard[randTile]
    } else if (tileSize === TileSize.QUARTER) {
        randTile = Math.floor(Math.random() * fillersData.basic.length + 0)
        obj = fillersData.basic[randTile]
    } else if (tileSize === TileSize.SMALL) {
        randTile = Math.floor(Math.random() * fillersData.none.length + 0)
        obj = fillersData.none[randTile]
    }
    const rand = Math.floor(Math.random() * 100)
    const imageUrl = rand % 2 === 0 ? "" : "/branding/placeholder-shapes.png"

    if (obj) {
        return {
            id: "filler" + Math.random(),
            name: obj?.title,
            teaser: obj?.subtitle ? obj?.subtitle : "",
            contacts: [],
            isFiller: false,
            filler: null,
            basisPremiumConfig: getBasisPremiumConfigForValues(tileSize, imageUrl.length > 0),
            fillerLink: obj.to,
            fillerLinkText: obj?.link,
            fillerColor: color,
            backgroundImageURL: imageUrl,
            stands: obj.stands,
            categories: obj.categories
        }
    }
    return null
}

function makeGrid(
    exhibitors: Exhibitor[],
    sectionType: SectionType,
    itemCount: number,
    fillersData: any,
    ignoreBasisPremium: boolean
): TileData[] {
    let availableColumns = 24
    let currentGrid: TileData[] = []

    for (let index = 0; index < exhibitors.length; index++) {
        const item = exhibitors[index]

        const exhibitor = item as Exhibitor

        let basisPremiumConfig = getBasisPremiumConfig(ignoreBasisPremium ? BasisPremiumType.NONE : exhibitor.basisPremium)

        if (sectionType === SectionType.TOP && branding.exhibitorsPageContent.specialTileSizeForSponsors) {
            basisPremiumConfig = getBasisPremiumConfig(BasisPremiumType.SPONSOR)
        }

        const neededColumnsForTileSize = getNumColumnsForTileSize(basisPremiumConfig.tileSize)
        if (availableColumns === 0) availableColumns = 24

        if (neededColumnsForTileSize > availableColumns) {
            const fillers = getNeededFillerSizes(availableColumns)
            for (let j = 0; j < fillers.length; j++) {
                const tileSize = getTileSizeForNumColumns(fillers[j])

                const content = getFillerData(tileSize, "#FFF", fillersData)
                const filler = getFillerData(
                    tileSize,
                    branding.exhibitorsPageContent.tilePlaceholderBgColor ?? "#000",
                    fillersData
                )
                let withBackgroundImage = !!(content?.backgroundImageURL && content.backgroundImageURL?.length > 0)
                currentGrid.push({
                    ...content!,
                    basisPremiumConfig: getBasisPremiumConfigForValues(tileSize, withBackgroundImage),
                    isFiller: true,
                    filler: filler
                })
            }
            index--
            availableColumns = 24
        } else {
            availableColumns -= neededColumnsForTileSize
            if (neededColumnsForTileSize === 3) {
                let nextExhibitor: Exhibitor | undefined = undefined

                for (let nextExhibitorIndex = index + 1; nextExhibitorIndex < exhibitors.length; nextExhibitorIndex++) {
                    if (exhibitors[nextExhibitorIndex].entityType === "organization") {
                        nextExhibitor = exhibitors[nextExhibitorIndex] as Exhibitor
                        break
                    }
                }
                const nextBasisPremiumConfig = nextExhibitor
                    ? getBasisPremiumConfig(ignoreBasisPremium ? BasisPremiumType.NONE : nextExhibitor.basisPremium)
                    : undefined
                if (nextExhibitor && nextBasisPremiumConfig && nextBasisPremiumConfig.tileSize === TileSize.SMALL) {
                    currentGrid.push({
                        ...exhibitor,
                        basisPremiumConfig: basisPremiumConfig,
                        filler: { ...nextExhibitor, basisPremiumConfig: nextBasisPremiumConfig, isFiller: false, filler: null }
                    })
                    index++
                } else {
                    const filler = getFillerData(
                        basisPremiumConfig.tileSize,
                        branding.exhibitorsPageContent.tilePlaceholderBgColor ?? "#000",
                        fillersData
                    )
                    if (filler) filler!.isFiller = true
                    currentGrid.push({ ...exhibitor, basisPremiumConfig: basisPremiumConfig, filler: filler })
                }
            } else {
                currentGrid.push({
                    ...exhibitor,
                    basisPremiumConfig: basisPremiumConfig,
                    basisPremiumType: exhibitor.basisPremium,
                    filler: null
                })
            }
        }
    }
    return currentGrid
}

/* #region  Main content */
interface CompaniesTilesLayoutProps {
    type: DetailNavLinkType
    sections: Sections
    isSponsor: boolean
    isMediaPartner: boolean
    isStartup: boolean
    searchKrit?: string
}

export const CompaniesTilesLayout: React.FC<CompaniesTilesLayoutProps> = React.memo((props) => {
    const [grids, setGrids] = useState<TileData[][]>([])
    const strings = useLanguageState().getStrings()
    const fillersData = strings.exhibitorsPageContent.fillersData

    //check if is sponsors page and if basisPremium should be ignored
    const isSponsorPage = useRouteMatch(sponsorsPageRoute)
    const isMediaPartnerPage = useRouteMatch(mediaPartnerPageRoute)
    const isExhibitorStartupPage = useRouteMatch(exhibitorStartupPageRoute)

    useEffect(() => {
        const gridsTemp: TileData[][] = []

        sectionOrder.forEach((sectionType: SectionType) => {
            const section = props.sections[sectionType]

            const grid = section
                ? makeGrid(section.entities as Exhibitor[], section.type, section.count, fillersData, ignoreBasisPremium!)
                : []

            gridsTemp.push(grid)
        })

        setGrids(gridsTemp)
        // eslint-disable-next-line
    }, [props.sections])

    const ignoreBasisPremium =
        isSponsorPage && isMediaPartnerPage && isExhibitorStartupPage && branding.sponsorsPageContent.ignoreBasisPremium
    return (
        <>
            {sectionOrder.map((sectionType, index) => {
                const section = props.sections[sectionType]
                if (!section || section.count === 0) return null
                const titleSponsors = `${strings.receptionPage.sponsorsTitle}(${section.count.toString()})`
                const titleTemplate = props.isSponsor
                    ? titleSponsors
                    : section.type === SectionType.TOP
                    ? strings.exhibitorsPageContent.sectionHeaderTop
                    : strings.exhibitorsPageContent.sectionHeaderAllExhibitors
                const src: UserOrganizationVisitSource = props.isSponsor
                    ? "SPONSORS"
                    : section.type === SectionType.TOP
                    ? "FLOORSPONSOR"
                    : "FLOOR"
                const title = titleTemplate.split("{$count}").join(section.count.toString())
                const grid = grids[index]

                return (
                    <div key={index} style={{ marginBottom: "10px" }}>
                        <SectionHeaderTitle>{title}</SectionHeaderTitle>
                        <CompanyGrid>
                            <CompanyRow>
                                {grid &&
                                    grid.map((tileData) => {
                                        return (
                                            <OrganizationTile
                                                key={tileData.id}
                                                src={src}
                                                searchKrit={props.searchKrit}
                                                tileData={tileData}
                                                strings={strings}
                                            />
                                        )
                                    })}
                            </CompanyRow>
                        </CompanyGrid>
                    </div>
                )
            })}
        </>
    )
})

function getBasisPremiumConfigForValues(tileSize: TileSize, tileBackgroundImageVisible: boolean) {
    const basisPremiumConfig = getDefaultBasisPremiumConfig()
    basisPremiumConfig.tileSize = tileSize
    basisPremiumConfig.tileBackgroundImageVisible = tileBackgroundImageVisible

    return basisPremiumConfig
}

/* #endregion */
