import { fetchDataRest, saveFile, topic } from "./BackendServicesUtils"
import { defaultLogger as logger } from "../globalStates/AppState"

export async function trackOrganizationDetailsPageVisit(
    profileId: string,
    organizationId: string,
    source: UserOrganizationVisitSource,
    searchKrit?: string
): Promise<string> {
    return trackVisit(profileId, organizationId, ("DETAIL#" + source) as UserOrganizationVisitType, searchKrit)
}

export async function trackEventDateDetailsPageVisit(
    profileId: string,
    organizationId: string,
    source: UserOrganizationVisitSource,
    eventDateId: string
): Promise<string> {
    return trackVisit(profileId, organizationId, ("EVENTDATE#" + source) as UserOrganizationVisitType, eventDateId)
}

export async function trackJobOfferPageVisit(
    profileId: string,
    organizationId: string,
    source: UserOrganizationVisitSource,
    jobOfferId: string
): Promise<string> {
    return trackVisit(profileId, organizationId, ("JOBOFFER#" + source) as UserOrganizationVisitType, jobOfferId)
}

export async function trackNewsPageVisit(
    profileId: string,
    organizationId: string,
    source: UserOrganizationVisitSource,
    newsId: string
): Promise<string> {
    return trackVisit(profileId, organizationId, ("NEWS#" + source) as UserOrganizationVisitType, newsId)
}

export async function trackLiveStreamDetailsPageVisit(
    profileId: string,
    organizationId: string,
    source: UserOrganizationVisitSource,
    eventDateId: string
) {
    return trackVisit(profileId, organizationId, ("STREAM#" + source) as UserOrganizationVisitType, eventDateId)
}

export async function trackOnDemandDetailsPageVisit(profileId: string, organizationId: string, eventDateId: string) {
    return trackVisit(profileId, organizationId, "VOD", eventDateId)
}

// getrackte Quellen
export type UserOrganizationVisitSource =
    // für OrganizationDetails
    | "FLOOR"
    | "FLOORSPONSOR"
    | "SPONSORS"
    | "COUPON"
    | "BANNER"
    | "SIDEBAR"
    | "LOBBY"
    | "LOBBYSPONSOR"
    | "VC"
    | "BOOKMARK"
    | "PROFILE"
    | "EVENTDATE"
    | "HALLPLAN"
    | "GUEST"
    | "STARTUP"
    // 'NOTIFICATION' |
    // // für Livestreams
    // 'ORGANIZATION' |
    // 'EVENTDATE' |
    // // alle?
    | "PERSON"
    | "SEARCH"
    | "SEARCHSPONSOR"
    | "PRODUCT"
    | "TRADEMARK"
    | "COLLECTION"
    | "NEWS"
    | "JOBOFFER"
    | "EVENT"
    | "ORGANIZATION"
    | "SCHEDULE"
    | "UNKNOWN"

export type UserOrganizationVisitType =
    // 'DETAIL#FLOOR' |
    // 'DETAIL#SPONSORS' |
    // 'DETAIL#COUPON' |
    // 'DETAIL#BANNER' |
    // 'DETAIL#SIDEBAR' |
    // 'DETAIL#LOBBY' |
    // 'DETAIL#VC' |
    // 'DETAIL#BOOKMARK' |
    // 'DETAIL#PROFILE' |
    // 'DETAIL#EVENTDATE' |
    | "EXPO"
    | "CALENDARENTRY#CLICK"
    | "CALENDARENTRY#SENT"
    | "INTEREST#CLICK"
    | "INTEREST#SENT"
    | "RECOMMENDATION#CLICK"
    | "RECOMMENDATION#SENT"
    | "VC#DETAIL"
    | "VC#LOBBY"
    | "VC#VC"
    | "VCROOM"
    | "LINK"
    | "MEDIA#PREVIEW"
    | "MEDIA#DOWNLOAD"
    | "STREAM#DETAIL"
    | "VOD"
    | "STREAM#ORGANIZATION"
    | "STREAM#LOBBY"
    | "STREAM#EVENT"
    | "STREAM#SCHEDULE"
    | "STREAM#BOOKMARK"
    | "STREAM#PERSON"
    | "STREAM#SEARCH"
    | "EVENTDATE#ORGANIZATION"
    | "EVENTDATE#LOBBY"
    | "EVENTDATE#EVENT"
    | "EVENTDATE#SCHEDULE"
    | "EVENTDATE#PERSON"
    | "EVENTDATE#BOOKMARK"
    | "EVENTDATE#SEARCH"
    | "COUPON"
    | "MAGAZINE"
    | "PRESS"

interface UserOrganizationVisitParam {
    time: string
    type: UserOrganizationVisitType
    targetId?: string
    duration?: string
}

export async function trackVisit(
    profileId: string,
    organizationId: string,
    type: UserOrganizationVisitType,
    targetId?: string,
    duration?: string
): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/profile/${profileId}/organization/${organizationId}`
    const payload: UserOrganizationVisitParam = { time: new Date().toISOString(), type: type, duration }
    if (targetId) {
        payload.targetId = targetId
    }
    try {
        const result = await fetchDataRest(defaultRoute, null, "POST", payload)
        if ((result as any).httpStatus) {
            return "FAIL"
        }
        return "SUCCESS"
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            payload,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

export interface LiveTrackingDatapoint {
    visitDate: string
    cnt: number
    type: string
}

export async function getLiveTrackingResults(
    profileId: string,
    organizationId: string,
    type: string,
    startDate: string,
    endDate: string
): Promise<LiveTrackingDatapoint[] | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/profile/${profileId}/organization/${organizationId}`
    try {
        const result = await fetchDataRest(defaultRoute, { type, startDate, endDate }, "GET")
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export interface SeriesOfTopicUserWithoutCta {
    profileId: string
    firstName?: string
    lastName?: string
    company?: string
    position?: string
    logoUrl?: string
}

export interface SeriesOfTopicUserWithCta extends SeriesOfTopicUserWithoutCta {
    detail: number
    expo: number
    ce: number
    interest: number
    rec: number
    vc: number
    link: number
    media: number
    live: number
    eventDate: number
    coupon: number
    press: number
    magazine: number
    lastvisit: string
    marked?: string
    markedBy?: string
    markedtext?: string
    optIn?: boolean
}

export interface UserOrganizationVisit {
    marked?: string
    markedBy?: string
    markedtext?: string
}

export async function markVisitor(
    visitorId: string,
    organizationId: string,
    profileId: string,
    markedText: string
): Promise<UserOrganizationVisit | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/visitors`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            {
                profileId: profileId,
                organizationId: organizationId,
                visitorId: visitorId,
                markedText: markedText,
                time: new Date().toISOString(),
                marked: true
            },
            "POST"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result as UserOrganizationVisit
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export async function unmarkVisitor(
    visitorId: string,
    organizationId: string,
    profileId: string
): Promise<UserOrganizationVisit | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/visitors`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            { profileId: profileId, organizationId: organizationId, visitorId: visitorId, marked: false },
            "POST"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result as UserOrganizationVisit
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export interface SeriesOfTopicUserWithCtaResponse {
    hasNext: boolean
    nextTime?: string
    data: SeriesOfTopicUserWithCta[]
}

export async function getVisitorsWithCtas(
    organizationId: string,
    optInOnly: boolean,
    unmarked: boolean,
    nextDate?: string
): Promise<SeriesOfTopicUserWithCtaResponse | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/visitors`
    const queryParams: any = { organizationId, unmarked, optInOnly, limit: 20 }
    if (nextDate) {
        queryParams.nextDate = nextDate
    }
    try {
        const result = await fetchDataRest(defaultRoute, queryParams, "GET")
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export interface SeriesOfTopicUserWithoutCtaResponse {
    hasNext: boolean
    data: SeriesOfTopicUserWithoutCta[]
}

export async function getOrganizationGuests(
    organizationId: string,
    itemsPerPage: number,
    page: number,
    searchString: string
): Promise<SeriesOfTopicUserWithoutCtaResponse | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/guests`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            { organizationId: organizationId, itemsPerPage: itemsPerPage, page: page, searchString: searchString },
            "GET"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export async function organizationGuestsExport(organizationId: string): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/guests/csv`
    const params = { organizationId: organizationId }
    try {
        const result = await fetchDataRest(defaultRoute, params, "GET", undefined, (resp: Response) =>
            saveFile(resp, "guests.csv")
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

export async function getOrganizationContacts(
    organizationId: string,
    itemsPerPage: number,
    page: number,
    searchString: string
): Promise<SeriesOfTopicUserWithoutCtaResponse | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/contacts`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            { organizationId: organizationId, itemsPerPage: itemsPerPage, page: page, searchString: searchString },
            "GET"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export async function organizationContactsExport(profileId: string, organizationId: string): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/contacts/csv`
    const params = { profileId: profileId, organizationId: organizationId }
    try {
        const result = await fetchDataRest(defaultRoute, params, "GET", undefined, (resp: Response) =>
            saveFile(resp, "contacts.csv")
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

export async function getUserDb(
    organizationId: string,
    itemsPerPage: number,
    page: number,
    searchString: string
): Promise<SeriesOfTopicUserWithoutCtaResponse | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/users`
    try {
        const result = await fetchDataRest(
            defaultRoute,
            { organizationId: organizationId, itemsPerPage: itemsPerPage, page: page, searchString: searchString },
            "GET"
        )
        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

export async function userDbExport(profileId: string, organizationId: string): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/users/csv`
    const params = { profileId: profileId, organizationId: organizationId }
    try {
        const result = await fetchDataRest(defaultRoute, params, "GET", undefined, (resp: Response) =>
            saveFile(resp, "users.csv")
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}

export async function visitorsWithCtasExport(organizationId: string, optInOnly: boolean): Promise<string> {
    const defaultRoute: string = `/tracking/topic/${topic}/visitors/csv`
    const params = { organizationId, optInOnly }
    try {
        const result = await fetchDataRest(defaultRoute, params, "GET", undefined, (resp: Response) =>
            saveFile(resp, optInOnly ? "leads.csv" : "visitors.csv")
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "FAIL"
    }
}
